export const WORDS = [
  'تخریب',
  'ابزار',
  'سابقه',
  'آسمان',
  'رضایت',
  'شناخت',
  'حکومت',
  'قاچاق',
  'واقعی',
  'رسانه',
  'آزادی',
  'اتحاد',
  'موضوع',
  'سفارت',
  'تولید',
  'طراحی',
  'زندگی',
  'سینما',
  'سلامت',
  'امسال',
  'توصیف',
  'استاد',
  'فضایی',
  'سرویس',
  'هزینه',
  'اخبار',
  'تهران',
  'نمایش',
  'قانون',
  'مرکزی',
  'پایان',
  'نگران',
  'ضروری',
  'حمایت',
  'تحقیق',
  'برنده',
  'مرحله',
  'محترم',
  'استان',
  'مختلف',
  'درمان',
  'مهندس',
  'سنگین',
  'مدرسه',
  'دولتی',
  'تسلیم',
  'اسفند',
  'تمرین',
  'آزمون',
  'خسارت',
  'طبیعت',
  'دخالت',
  'عضویت',
  'آگاهی',
  'سیاست',
  'فرنگی',
  'مستقل',
  'توافق',
  'جایزه',
  'تقویت',
  'اجازه',
  'جریان',
  'تامین',
  'جنایت',
  'تحلیل',
  'منطقه',
  'زباله',
  'مظلوم',
  'حادثه',
  'شماره',
  'البته',
  'جمعیت',
  'اسلام',
  'وظیفه',
  'امنیت',
  'تجاوز',
  'کنترل',
  'منتشر',
  'وضعیت',
  'متخصص',
  'هدایت',
  'فرهنگ',
  'تاریخ',
  'فولاد',
  'تصمیم',
  'موجود',
  'مقاله',
  'مشترک',
  'طبیعی',
  'بیمار',
  'کیفیت',
  'بحران',
  'دیدار',
  'همایش',
  'پروژه',
  'افراد',
  'صندوق',
  'معاون',
  'محصول',
  'رقابت',
  'فهرست',
  'خودرو',
  'باران',
  'اصلاح',
  'مساحت',
  'تغییر',
  'شخصیت',
  'پایین',
  'داخلی',
  'گسترش',
  'رابطه',
  'ریاست',
  'وسیله',
  'تجارت',
  'پرورش',
  'آلوده',
  'عدالت',
  'احساس',
  'هنگام',
  'تربیت',
  'تالار',
  'خاطره',
  'کمیته',
  'نوروز',
  'بنزین',
  'سراسر',
  'تحریم',
  'وزارت',
  'میراث',
  'انسان',
  'منظور',
  'خرداد',
  'زلزله',
  'ولایت',
  'توزیع',
  'پرواز',
  'انرژی',
  'مستمر',
  'توسعه',
  'گذشته',
  'ماشین',
  'حداقل',
  'دیروز',
  'پژوهش',
  'تشخیص',
  'پنهان',
  'آینده',
  'تهدید',
  'نقاشی',
  'تصویر',
  'مربوط',
  'جامعه',
  'میزان',
  'حفاظت',
  'تفاوت',
  'رادیو',
  'فارسی',
  'فلسفه',
  'تعریف',
  'صورتی',
  'نزدیک',
  'نمونه',
  'انجمن',
  'فرزند',
  'همدان',
  'تفاهم',
  'هشدار',
  'آخرین',
  'گزارش',
  'بهینه',
  'حقیقت',
  'نشریه',
  'ایمان',
  'اداره',
  'تجربه',
  'روسیه',
  'ایران',
  'خصوصی',
  'روستا',
  'اهداف',
  'تعامل',
  'جزیره',
  'ترجمه',
  'نظارت',
  'تحصیل',
  'فراهم',
  'پدیده',
  'اولین',
  'ستاره',
  'امروز',
  'ورزشی',
  'نتیجه',
  'ذخیره',
  'دستور',
  'گفتگو',
  'معادل',
  'بودجه',
  'دقیقه',
  'تصویب',
  'ارسال',
  'مخالف',
  'سرباز',
  'تشکیل',
  'سیستم',
  'اهمیت',
  'برابر',
  'علاقه',
  'ایجاد',
  'همراه',
  'مراسم',
  'میدان',
  'معاصر',
  'دیوان',
  'زندان',
  'عشایر',
  'بازار',
  'کوتاه',
  'مشاور',
  'پیاده',
  'نهایت',
  'نظامی',
  'اروپا',
  'ترتیب',
  'بررسی',
  'مناسب',
  'نهایی',
  'محکوم',
  'نامزد',
  'سیمان',
  'حاشیه',
  'آموزش',  
  'ترکیب',
  'مشروع',
  'معتبر',
  'ابداع',
  'آبشار',
  'اصالت',
  'تجدید',
  'افسوس',
  'اجبار',
  'ترسیم',
  'اخلاق',
  'معرفت',
  'الفبا',
  'آشپزی',
  'اندوه',
  'اصابت',
  'اژدها',
  'موسسه',
  'سوغات',
  'مرطوب',
  'انتها',
'سرطان',
'نوروز',
'ناچار',
'پرتاب',
'باتری',
'تبعید',
'اغراق',
'تسکین',
'تکلیف',
'غریبه',
'احداث',
'مجتمع',
'خوشبو',
'معروف',
'کلیسا',
'انگشت',
'نظریه',
'نوازش',
'سیمرغ',
'مافیا',
'تقویم',
'کنسرت',
'تمرکز',
'درپوش',
'تابوت',
'ارادی',
'جنازه',
'توهین',
'دربار',
'زاگرس',
'خصومت',
'روایت',
'اطاعت',
'مخلوط',
'تاخیر',
'مقتدر',
'چگالی',
'شطرنج',
'مقبره',
'پاییز',
'مملکت',
'پربار',
'شهامت',
'ایراد',
'میانه',
'اعلام',
'روباه',
'کنکور',
'بهانه',
'اراده',
'سیگار',
'قناری',
'دیپلم',
'جراحی',
'معتاد',
'تفتیش',
'پیوست',
'فرمان',
'سردار',
'دهقان',
'ابهام',
'غنیمت',
'مجاور',
'کیمیا',
'تاریک',
'استخر',
'متعهد',
'دندان',
'حرارت',
'مخاطب',
'نساجی',
'حنجره',
'گمشده',
'متفکر',
'شمشیر',
'یاقوت',
'متمدن',
'منظره',
'لوبیا',
'دلفین',
'شکلات',
'بارور',
'فردوس',
'اشاره',
'قضاوت',
'معیوب',
'ناخدا',
'محکوم',
'جواهر',
'مسواک',
'نوسان',
'دوران',
'تعرفه',
'سلیقه',
'انبوه',
'آدامس',
'کدخدا',
'قلمرو',
'گودال',
'تخمیر',
'مزاحم',
'تمساح',
'تدریس',
'ماورا',
'زبانه',
'بیرون',
'قافیه',
'اکنون',
'عناصر',
'آموزش',
'بیداد',
'بستنی',
'شلوار',
'گلبرگ',
'حیوان',
'تهویه',
'راستا',
'گمراه',
'پیکار',
'سرزنش',
'انبار',
'اکران',
'بنفشه',
'متروک',
'اتصال',
'مزایا',
'سوراخ',
'مصلحت',
'ارشاد',
'تفرقه',
'کیهان',
'مافوق',
'آستین',
'چوپان',
'رخداد',
'پیروز',
'تجهیز',
'شهریه',
'خرچنگ',
'قیامت',
'سلطان',
'سمندر',
'فرسنگ',
'رطوبت',
'بهبود',
'تعظیم',
'وثیقه',
'سوهان',
'تصحیح',
'خواهر',
'گیتار',
'استرس',
'ویژگی',
'تلمبه',
'تسلیت',
'پاشنه',
'متاهل',
'باغچه',
'گرداب',
'دامنه',
'فشردن',
'شجاعت',
'محسوس',
'کرانه',
'مبتکر',
'جسارت',
'انگور',
'خدمات',
'خاتون',
'ناکام',
'مقاوم',
'تناسب',
'اخطار',
'گوارش',
'دشوار',
'دایره',
'کیفیت',
'سیلاب',
'شیطان',
'تعویق',
'گرانش',
'ستایش',
'حافظه',
'هیاهو',
'تدبیر',
'ترفند',
'تقلبی',
'پستچی',
'محدود',
'تندرو',
'گمنام',
'موتور',
'سهمیه',
'سخنگو',
'بیدار',
'زراعت',
'همیشه',
'فنجان',
'صنعتی',
'فرمول',
'صحافی',
'کاربر',
'صنایع',
'محافظ',
'تقاطع',
'تخمین',
'دستکش',
'سنجاب',
'خلاصه',
'بازرس',
'تحقیر',
'مرموز',
'آشکار',
'اندام',
'توانا',
'عمومی',
'پیامد',
'پیوند',
'ترفیع',
'سرگرم',
'رکورد',
'مقابل',
'هندسه',
'الهام',
'گرایش',
'خیالی',
'مرداد',
'دونده',
'ساچمه',
'تسخیر',
'انجیر',
'گواهی',
'تشکیل',
'ممتاز',
'منحرف',
'نصیحت',
'آفتاب',
'پلیمر',
'چکیده',
'داماد',
'تبریک',
'بدرقه',
'متوسط',
'نشیمن',
'قدیمی',
'رسانا',
'عقیده',
'ممیزی',
'جبران',
'غرامت',
'بخاری',
'جویدن',
'طوفان',
'شناور',
'دهکده',
'خلبان',
'اجاره',
'فیزیک',
'معجزه',
'میمون',
'متغیر',
'قیافه',
'مجازی',
'شستشو',
'فاجعه',
'جاذبه',
'تعطیل',
'خرابه',
'مشهور',
'تنگنا',
'تشویق',
'زرافه',
'پیکان',
'صمیمی',
'طومار',
'مشتاق',
'مطبوع',
'بشقاب',
'مستند',
'سوگند',
'محبوب',
'مردود',
'تهاجم',
'گنجشک',
'لایحه',
'بازگو',
'صاعقه',
'موزون',
'تکامل',
'جانور',
'منسوخ',
'مصیبت',
'مقیاس',
'زمزمه',
'جریمه',
'سرکوب',
'اتهام',
'انکار',
'تومان',
'ارزان',
'پیکره',
'اندرز',
'عصاره',
'شیراز',
'مهاجم',
'مبتلا',
'خزنده',
'مسخره',
'ارادت',
'زنجیر',
'فرشته',
'قاجار',
'تجزیه',
'مرخصی',
'کمیاب',
'اشعار',
'معیار',
'اعدام',
'ضابطه',
'توضیح',
'مزدور',
'پرشور',
'توقیف',
'قبیله',
'تفریح',
'تدوین',
'مهتاب',
'مداوم',
'مهلکه',
'تغذیه',
'مراکز',
'مجسمه',
'ضمانت',
'ایوان',
'یخچال',
'لبخند',
'شاگرد',
'دسیسه',
'عبارت',
'بنیاد',
'قناعت',
'لیاقت',
'مراجع',
'حیثیت',
'خربزه',
'مشکوک',
'پزشکی',
'ممنوع',
'تبخیر',
'ییلاق',
'تنبیه',
'شکنجه',
'کانال',
'مجبور',
'واکنش',
'مجموع',
'ترویج',
'آسایش',
'عنوان',
'عروسک',
'بسامد',
'نهفته',
'تشبیه',
'جمجمه',
'ملکوت',
'اشتها',
'مخصوص',
'محلول',
'جوانه',
'دغدغه',
'کابوس',
'مصادف',
'تجاری',
'بادام',
'فریاد',
'ضخامت',
'نعناع',
'تشعشع',
'نوزاد',
'مترجم',
'سرایت',
'شیپور',
'پنجره',
'سنجاق',
'منقار',
'روزنه',
'بیکار',
'تصفیه',
'بوران',
'عاقبت',
'تحویل',
'اتفاق',
'جنگجو',
'کودتا',
'فشرده',
'غمگین',
'بدخیم',
'تعقیب',
'شیرجه',
'مسافر',
'شایعه',
'باریک',
'آماده',
'دلهره',
'چرتکه',
'گلدان',
'تردید',
'ریاضی',
'گلوله',
'کانون',
'ثانیه',
'مظنون',
'خیریه',
'تحریف',
'صداقت',
'تلاقی',
'آلبوم',
'پوشاک',
'پیشکش',
'قطعات',
'وسواس',
'پلکان',
'پیمان',
'کرایه',
'مبارز',
'تعداد',
'خجسته',
'فانوس',
'ملموس',
'همبند',
'آفرین',
'عملگر',
'نیشکر',
'درویش',
'اطراف',
'انزوا',
'حلزون',
'ققنوس',
'مشغول',
'خندان',
'یزدان',
'سنتور',
'مرغوب',
'بسیار',
'بربری',
'طلایی',
'رگبار',
'اکسیر',
'شکافت',
'سرهنگ',
'تقسیم',
'نارنج',
'بداهه',
'مسدود',
'ورودی',
'پیانو',
'تضمین',
'ادویه',
'دلاور',
'عبادت',
'حسادت',
'کاستی',
'معقول',
'عقربه',
'بازده',
'اسکله',
'غضروف',
'خاموش',
'سلسله',
'تبلیغ',
'سپرده',
'نگارش',
'زاویه',
'مجنون',
'تصاحب',
'آدینه',
'جنجال',
'عفونت',
'تماشا',
'قزوین',
'مورچه',
'معکوس',
'سرخاب',
'جوراب',
'ترازو',
'فاصله',
'سهیلا',
'رساله',
'ارباب',
'فایده',
'آرشیو',
'سانحه',
'تفسیر',
'کنگره',
'تخفیف',
'رفتار',
'چپاول',
'صابون',
'راهرو',
'محفظه',
'اشباع',
'دیابت',
'کبوتر',
'معرفی',
'خرگوش',
'حفاری',
'کتیبه',
'کوتاه',
'الیاف',
'انجام',
'تصادف',
'اتاقک',
'مغازه',
'زودرس',
'انصاف',
'متنفر',
'الماس',
'جاسوس',
'معمار',
'مخدوش',
'آرایش',
'تثبیت',
'مناره',
'تعمیر',
'آسیاب',
'گمانه',
'مسموم',
'حراست',
'سفارش',
'ضیافت',
'شکایت',
'چکاوک',
'پاداش',
'مقتول',
'مهارت',
'گلچین',
'آرامش',
'باروت',
'پارچه',
'خشنود',
'پیشوا',
'پرهیز',
'وسوسه',
'تحریک',
'اقلیم',
'برادر',
'مشتری',
'مفقود',
'تقارن',
'فریزر',
'باتوم',
'درنده',
'سرپوش',
'دیوار',
'برچسب',
'البرز',
'پذیرش',
'ظرفیت',
'تمدید',
'اخراج',
'سالاد',
'نیرنگ',
'ریحان',
'معتمد',
'ترکیب',
'مجهول',
'غریزه',
'دریچه',
'همسفر',
'میرزا',
'چاشنی',
'گشایش',
'منزوی',
'آهنگر',
'تندیس',
'رهبری',
'موافق',
'اکنون',
'مختصر',
'تقطیر',
'ناهار',
'محتوا',
'ناحیه',
'تمجید',
'مقدمه',
'مرکزی',
'میلاد',
'جهانی',
'هیجان',
'آذرخش',
'فرجام',
'تاجیک',
'ظرافت',
'مرثیه',
'زنبور',
'معترض',
'مقدار',
'شرایط',
'ناقوس',
'خروجی',
'حماسه',
'دادگر',
'امداد',
'ممتنع',
'مامور',
'همکار',
'تقصیر',
'دردسر',
'کبریت',
'نشانی',
'ویروس',
'خیانت',
'آذوقه',
'جستجو',
'ایمنی',
'مصوبه',
'کتیبه',
'ویران',
'منتقل',
'شکوفه',
'مازاد',
'خارجی',
'منابع',
'خستگی',
'افسار',
'کارگر',
'گرسنه',
'هکتار',
'احضار',
'لیوان',
'تابلو',
'واکسن',
'تالاب',
'خزانه',
'نوشته',
'اسفنج',
'طالبی',
'تبانی',
'متعصب',
'کنایه',
'جداره',
'مخلوق',
'سیاره',
'تشابه',
'تکمیل',
'زینتی',
'یگانه',
'علامت',
'فقدان',
'مبادا',
'منجمد',
'برهنه',
'نابغه',
'مرداب',
'ملوان',
'معتقد',
'تخلیه',
'پرنده',
'اخلال',
'سپهبد',
'گزینه',
'پدرام',
'تسبیح',
'عمارت',
'معلول',
'نجابت',
'مصدوم',
'ارابه',
'همسان',
'مزرعه',
'چنگال',
'آلیاژ',
'فروتن',
'قرینه',
'مهاجر',
'مجذوب',
'سریال',
'مرتبط',
'مشورت',
'تلافی',
'وجدان',
'پرستو',
'غواصی',
'شازده',
'تاثیر',
'سیاسی',
'کمبود',
'کرگدن',
'حکایت',
'اسلحه',
'بیشتر',
'زیتون',
'پیشرو',
'ابتدا',
'نیمکت',
'مفهوم',
'منتقد',
'تقاضا',
'آوازه',
'سرکار',
'اساسی',
'مشابه',
'کارون',
'بنیان',
'درگیر',
'اینجا',
'جدایی',
'ترمیم',
'تکنیک',
'محروم',
'اقامت',
'زندگى',
'مجانی',
'پیگرد',
'شرافت',
'شیرین',
'تنظیم',
'کلوچه',
'مهمان',
'نتایج',
'مساوی',
'منتظر',
'تراکم',
'ابتلا',
'زیارت',
'سلطنت',
'پیاله',
'گروهی',
'دهخدا',
'صیانت',
'معصوم',
'ترانه',
'تبدیل',
'ارجاع',
'طاعون',
'منفجر',
'ضربان',
'سرسبز',
'تخصصی',
'مسافت',
'ابلاغ',
'اسکلت',
'گیلاس',
'جوایز',
'گردان',
'شکوفا',
'حوضچه',
'آبرنگ',
'تحسین',
'نکوهش',
'موازی',
'ادامه',
]
